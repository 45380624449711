import { Model } from "@vuex-orm/core";
import uuid from "uuid";

export default class Parcel extends Model {
  static entity = "parcel";
  static primaryKey = "uuid";

  static fields() {
    return {
      id: this.uid(() => uuid()),
      uuid: this.attr(),
      project: this.attr(null),
      parcelArea: this.attr(0),
      parcelNumber: this.attr(""),
    };
  }
}
