import { Model } from "@vuex-orm/core";
import Marker from "./Marker";

export default class Component extends Model {
  static entity = "components";
  static primaryKey = "uuid";
  static aosType = "COMPONENT";

  static fields() {
    return {
      uuid: this.attr(),
      label: this.attr(),
      type: this.attr(null),
      usage: this.attr(null),
      content: this.attr(null),
      masonryPit: this.attr(null),
      position: this.attr(null),
      locationInerted: this.attr(null),
      inertageDate: this.attr(null),
      remplacementDate: this.attr(null),
      inertageMethod: this.attr(null),
      isRetention: this.boolean(null),
      waterproofSurface: this.attr(null),
      temporality: this.attr(null),
      toolType: this.attr(null),
      impactedEnvironment: this.attr(null),
      fuelEnergy: this.attr(null),
      dielectricPresence: this.attr(null),
      dielectric: this.attr(null),
      storageType: this.attr(null),
      description: this.attr(null),
      backfillType: this.attr(null),
      nature: this.attr(null),
      areaPositioning: this.attr(null),
      surface: this.attr(null),
      isWaterproof: this.boolean(null),
      usedFluids: this.attr(null),
      state: this.attr(null),
      packaging: this.attr(null),
      usedSolvant: this.attr(null),
      productTypes: this.attr(null),
      structureDepth: this.attr(null),
      height: this.attr(null),
      wastesType: this.attr(null),
      sphcConnected: this.attr(null),
      underTension: this.attr(null),
      waterDepth: this.attr(null),
      integratedTank: this.attr(null),
      isKnownDepth: this.boolean(null),
      isCompartment: this.boolean(null),
      depth: this.attr(null),
      volume: this.attr(null),
      width: this.attr(null),
      length: this.attr(null),
      rejection: this.attr(null),
      isRemoteUnloading: this.boolean(null),
      rejectionLocation: this.attr(null),
      materials: this.attr(null),
      doubleSkin: this.attr(null),
      installationDate: this.attr(null),
      isPollutionTraces: this.boolean(false),
      remarks: this.attr(null),
      pictureUuid: this.attr(null),
      tagsTypeList: this.attr([]),
      typology: this.attr("COMPONENT"),
      details: this.attr(null),
      tracerCompound: this.attr(),
      analyticalStandard: this.attr(null),
      quantificationLimit: this.attr(null),
      isSourceTakenIntoAccount: this.boolean(true),
      justification: this.attr(null),
      diameter: this.attr(null),

      marker: this.morphOne(Marker, "markable_id", "markable_type"),
    };
  }
}
