import { Model } from "@vuex-orm/core";
import Analysis from "./Analysis";
import { SAMPLES_STATUS, SAMPLES_TYPES } from "@/constants/samples";
import ComplementaryAnalysis from "@/models/ComplementaryAnalysis";

export default class Sample extends Model {
  static entity = "samples";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      name: this.attr(),
      sampleType: this.attr(SAMPLES_TYPES.STANDARD),
      sampleMatrix: this.attr(),
      status: this.attr(SAMPLES_STATUS.NOT_ENTERED),
      sampler: this.attr(),
      samplingProtocol: this.attr(),
      heapHeight: this.number(),
      heapLength: this.number(),
      heapWidth: this.number(),
      heapVolume: this.number(),
      samplingNature: this.attr(),
      canisterVolume: this.number(),
      isControlSample: this.boolean(),
      controlSamplePlacement: this.attr(),
      isBlank: this.boolean(),
      isTerrainBlank: this.boolean(),
      isTransportBlank: this.boolean(),
      supportType: this.attr(),
      batchNumber: this.attr(),
      serialNumber: this.attr(),
      expirationDate: this.attr(),
      samplingDepth: this.number(),
      finalPh: this.number(),
      finalTemperature: this.number(),
      finalConductivity: this.number(),
      lnapl: this.string(),
      dnapl: this.string(),
      samplingLineMaterial: this.attr(),
      samplingLineLength: this.number(),
      filter: this.attr(),
      samplingMaterialUsedPumpsList: this.attr([]),
      samplingMaterialUsedFlowmeter: this.attr(),
      isFirstSample: this.attr(false),
      pumpInitialFlow: this.number(),
      pumpIntermediateFlow: this.number(),
      pumpFinalFlow: this.number(),
      samplingEndDatetime: this.attr(),
      samplingTop: this.number(),
      sampleBottomDepth: this.attr(null),
      isLithologicalLayerBottomReached: this.attr(),
      isSampleBottom: this.attr(false),
      layerBottomDepth: this.attr(),
      organolepticIndex: this.attr(),
      pidMeasure: this.attr(),
      pidInitialMeasure: this.attr(),
      pidIntermediateMeasure: this.attr(),
      pidFinalMeasure: this.attr(),
      pumpAverageFlow: this.number(),
      isSamplingLengthValid: this.boolean(),
      samplingDuration: this.number(),
      validFlowDifference: this.attr(),
      retainedFlow: this.number(),
      volumeSampled: this.number(),
      barCodes: this.attr([]),
      description: this.attr(),
      datetime: this.attr(),
      analysis: this.attr([]),
      survey: this.attr(),
      lithologicalLayers: this.attr([]),
      analysisData: this.hasManyBy(Analysis, "analysis"),
      samplingUsedFlowmeter: this.attr(),
      complementaryAnalysis: this.hasMany(ComplementaryAnalysis, "sample"),
      samplingMaterialPump: this.attr(),
      lithologyLayer: this.attr(),
    };
  }
}
