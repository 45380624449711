import { Model } from "@vuex-orm/core";
export default class OutdoorSpace extends Model {
  static entity = "outdoorSpace";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      usesList: this.attr([]),
      isPollutionTrace: this.attr(false),
      waterproofedSurfaceRate: this.attr(0),
      nonWaterproofedSurfaceRate: this.attr(0),
      xCoordinate: this.attr(0),
      yCoordinate: this.attr(0),
      zCoordinate: this.attr(0),
      type: this.attr(""),
      topDownOrientation: this.attr(""),
      slopeRate: this.attr(0),
      referential: this.attr(""),
      project: this.attr(""),
    };
  }
}
