import { Model } from "@vuex-orm/core";
import Sample from "./Sample";
import LithologicalLayer from "@/models/LithologicalLayer";
export default class Survey extends Model {
  static entity = "surveys";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      name: this.attr(),
      surveyType: this.attr("SOIL"),
      surveyToolType: this.attr(),
      samplingTop: this.attr(),
      prefix: this.attr(),
      number: this.attr(),
      material: this.attr(),
      isSurveyRefusal: this.attr(false),
      isWaterPresent: this.attr(true),
      presenceOfWater: this.attr(0),
      surveyImage: this.attr(""),
      gpsReferential: this.attr("WGS84"),
      latitude: this.attr(""),
      longitude: this.attr(""),
      altitude: this.attr(""),
      bssCode: this.attr(""),
      localisation: this.attr(""),
      observations: this.attr(""),
      cuttingManagement: this.attr(""),
      fillingChoices: this.attr([]),
      samplingProtocol: this.attr(""),
      isSurveyEditionDone: this.boolean(),
      numberOfUnitSamples: this.attr(),
      implantationDate: this.attr(),
      weatherConditions: this.attr(),
      temperature: this.attr(),
      hasHumidity: this.attr(false),
      humidityRate: this.attr(),
      samplingHeight: this.attr(),
      hasVentilation: this.attr(false),
      hasHeater: this.attr(false),
      position: this.attr(),
      pointType: this.attr(),
      concreteSlabThickness: this.attr(),
      casingNature: this.attr(),
      fullTubeLength: this.attr(),
      totalCasingLength: this.attr(),
      casingInteriorDiameter: this.attr(),
      casingTotalDiameter: this.attr(),
      toolHeightAboveGround: this.attr(),
      toolTotalHeightImplanted: this.attr(),
      waterArrivalWhileDrilling: this.attr(),
      waterLevelDepth: this.attr(),
      concreteBase: this.attr(false),
      clayPlugThickness: this.attr(),
      clayPlugType: this.attr(),
      drainingMassThickness: this.attr(),
      hydrogeologicalPosition: this.attr(),
      isWaterproofTested: this.attr(false),
      isWaterproof: this.attr(),
      notes: this.attr(),
      strainedTubeHeight: this.number(),
      columnHeightFromSoilOnImplantationEnd: this.number(),
      columnVolumeFromSoil: this.number(),
      samples: this.hasMany(Sample, "survey"),
      lithologicalLayers: this.hasMany(LithologicalLayer, "survey"),
      excavatedSoilTypeOfSampling: this.attr(""),
      excavatedSoilLength: this.number(),
      excavatedSoilWidth: this.number(),
      excavatedSoilHeight: this.number(),
      excavatedSoilVolume: this.number(),
      excavatedSoilDepth: this.number(),
      excavatedSoilSamplingPlan: this.attr(),
    };
  }
}
