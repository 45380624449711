import HistoricalItem from "./HistoricalItem";

export default class Accident extends HistoricalItem {
    static entity = "accident";
    static primaryKey = "uuid";

    static fields() {
        return {
            date: this.attr(),
            description: this.attr(),
            canPollute: this.attr(),
            tracerPollutants: this.attr(),
            impactedEnvironmentsList: this.attr([]),
            riskDetails: this.attr(),
            measures: this.attr(),
            reason: this.attr(),
        };
    }
}