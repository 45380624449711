import Building from "./Building";
import Component from "./Component";
import Zone from "./Zone";
import OtherItem from "./OtherItem";
import AosSite from "./AosSite";

export const SITE = "SITE";
export const BUILDING = "BUILDING";
export const ZONE = "ZONE";
export const COMPONENT = "COMPONENT";
export const OTHER_ITEM = "OTHER_ITEM";

const AosMapping = {
  [BUILDING]: Building,
  [ZONE]: Zone,
  [SITE]: AosSite,
  [OTHER_ITEM]: OtherItem,
  [COMPONENT]: Component,
};

export const insertMarkers = (data) => {
  if (!Array.isArray(data)) data = [data];
  return data.map((datum) => insertMarker(datum));
};

export const insertMarker = (data) => {
  const Model = AosMapping[data.aosType];
  const [long, lat] = data.geolocation.split(",");
  if (data.manualGeolocation) {
    const [manualLong, manualLat] = data.manualGeolocation.split(",");
    data = {
      ...data,
      manualLat,
      manualLong,
    };
  }
  return Model.insert({
    data: {
      ...data,
      marker: {
        uuid: data.uuid,
        parent_uuid: data.site,
        lat,
        long,
      },
    },
  });
};
